/**
* Created by yx on 2017/12/21.
*/
export default {
    //弹窗提示自定义弹框
    showToast: function (msg, duration) {
        duration = isNaN(duration) ? 1000 : duration;
        var m = document.createElement('div');
        m.innerHTML = msg;
        m.style.cssText = "max-width: 300px;padding:15px;opacity: 0.7;color: rgb(255, 255, 255);line-height: 1.4;border-radius: 5px;position: fixed;top: 50%;left: 50%;z-index: 999999;background: rgb(0, 0, 0);font-size: 12px;transform: translate(-50%, -50%)";
        document.body.appendChild(m);
        setTimeout(function () {
            var d = 0.5;
            m.style.webkitTransition = '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in';
            m.style.opacity = '0';
            setTimeout(function () {
                document.body.removeChild(m)
            }, d * 1000);
        }, duration);
    },
}