<template>
  <div id="app">
    <Nav :is-transparent="false" />
    <div class="flex content">
      <div class="form-box">
        <div class="form">
          <div class="title">立即咨询</div>
          <div class="subtitle">惠合专业顾问将在2小时内联系您</div>
          <div class="flex">
            <div class="item require">
              <p>姓名</p>
              <input type="text" maxlength="20" v-model="name" class="inp" />
            </div>
            <div class="item require">
              <p>手机号码</p>
              <input type="tel" maxlength="11" v-model="phone" class="inp" />
            </div>
          </div>

          <div class="flex authcode">
            <div class="item require">
              <p>验证码</p>
              <input type="text" v-model="authCode" class="inp" />
            </div>
            <div :class="['btn', { disable: !smsSendBtn }]" @click="getCode">
              {{ smsSendBtn ? "获取验证码" : time + "s后重新获取" }}
            </div>
          </div>
          <div class="flex">
            <div class="item require company">
              <p>公司名称</p>
              <input
                type="text"
                v-model="companyName"
                maxlength="20"
                class="inp"
              />
            </div>
          </div>
          <div class="flex">
            <div class="item">
              <p>职位</p>
              <input
                type="text"
                v-model="companyJob"
                maxlength="20"
                class="inp"
              />
            </div>
            <div class="item">
              <p>邮箱</p>
              <input type="email" v-model="mail" class="inp" />
            </div>
          </div>

          <div class="about">
            <span>想要咨询：</span>
            <div class="category-box">
              <div
                :class="['category', { active: index === currentIndex }]"
                v-for="(item, index) in categoryList"
                :key="index"
                @click="handleClick(item, index)"
              >
                {{ item }}
              </div>
            </div>
          </div>

          <div class="submit" @click="handleSubmit">提交</div>
        </div>
        <div class="flex form-bottom">
          <div class="bottom-item">
            <img
              src="https://image.hzhuihe.cn/new_website/contact/icon.png"
              class="icon"
              alt=""
            />
            <p>一对一<br />专家顾问咨询</p>
          </div>
          <div class="bottom-item">
            <img
              src="https://image.hzhuihe.cn/new_website/contact/icon.png"
              class="icon"
              alt=""
            />
            <p>传统渠道<br />营销案例集</p>
          </div>
          <div class="bottom-item m0">
            <img
              src="https://image.hzhuihe.cn/new_website/contact/icon.png"
              class="icon"
              alt=""
            />
            <p>定制化<br />数字营销方案</p>
          </div>
        </div>
      </div>
    </div>
    <Bottom :isShowContact="false" />
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue"
import Bottom from "@/components/Bottom.vue"
import { doPost } from "@/utils/request"
import toast from "@/utils/toast"
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "Contact",
  components: {
    Nav,
    Bottom,
  },
  data() {
    return {
      time: 60,
      smsSendBtn: true,
      name: "",
      phone: "",
      authCode: "",
      companyJob: "",
      companyName: "",
      mail: "",
      content: "开箱扫码",
      currentIndex: 0,
      categoryList: [
        "开箱扫码",
        "生动化陈列",
        "到店核销",
        "一元乐享",
        "开盖有礼",
        "其他",
      ],
      interval: null,
    }
  },
  mounted() {
    const from = this.$route.query.from
    const moudle = this.$route.query.moudle
    window.sensors.track("consultPage", {
      source_page: from,
      source_module: moudle,
    })
  },
  methods: {
    // 获取验证码
    getCode() {
      if (!this.smsSendBtn) {
        return
      }
      if (!/^1[0-9]{10}$/.test(this.phone) || !this.phone) {
        toast.showToast("请输入格式正确的手机号")
        return
      }
      doPost("pms/officialWebsiteConsult/authcode.json", {
        phone: this.phone,
        smsBizCode: "ow_consult",
      }).then(() => {
        this.smsSendBtn = false
        this.interval = window.setInterval(() => {
          if (this.time-- <= 0) {
            this.time = 60
            this.smsSendBtn = true
            window.clearInterval(this.interval)
          }
        }, 1000)
      })
    },
    handleClick(item, index) {
      this.currentIndex = index
      this.content = item
    },
    // 提交
    handleSubmit() {
      if (this.name === "" || !this.name) {
        toast.showToast("请输入姓名")
        return
      }
      if (!/^1[0-9]{10}$/.test(this.phone) || !this.phone) {
        toast.showToast("请输入格式正确的手机号")
        return
      }
      if (this.authCode === "" || !this.authCode) {
        toast.showToast("请输入验证码")
        return
      }
      if (this.companyName === "" || !this.companyName) {
        toast.showToast("请输入公司名称")
        return
      }
      if (!/^\w+@[a-z0-9]+\.[a-z]{2,4}$/.test(this.mail) && this.mail) {
        toast.showToast("请输入格式正确的邮箱")
        return
      }
      window.sensors.track("submitConsultInfo", {
        name: this.name,
        phone_number: this.phone,
        company_name: this.companyName,
        job_position: this.companyJob,
        email: this.mail,
        consult_type: this.content,
      })
      doPost("pms/officialWebsiteConsult/doSave.json", {
        name: this.name,
        phone: this.phone,
        authCode: this.authCode,
        companyName: this.companyName,
        companyJob: this.companyJob,
        mail: this.mail,
        content: this.content,
      }).then(() => {
        toast.showToast("提交成功", 1500)
        setTimeout(() => {
          history.back()
        }, 1000)
      })
    },
  },
  destroyed() {
    window.clearInterval(this.interval)
  },
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
#app {
  padding-top: 88px;
  .content {
    width: 100%;
    padding: 40px 0;
    align-items: center;
    box-sizing: border-box;
    background: url("https://image.hzhuihe.cn/new_website/contact/bg.png")
      center no-repeat;
    background-size: 100%;
    .form-box {
      width: 640px;
      box-shadow: 0px 3px 8px 0px rgba(156, 156, 156, 0.3);
      .form {
        padding: 0 40px 34px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 3px 8px 0px rgba(156, 156, 156, 0.3);
        .title {
          padding-top: 40px;
          text-align: center;
          font-size: 46px;
          font-weight: 400;
          color: #141414;
        }
        .subtitle {
          margin-bottom: 48px;
          text-align: center;
        }
        .item {
          flex: 1;
          margin-bottom: 20px;
          &:first-child {
            margin-right: 50px;
          }
          &.company {
            margin-right: 0;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #333;
          }
          .inp {
            width: 100%;
            height: 40px;
            padding: 0 10px;
            border-radius: 4px;
            border: 1px solid rgba(170, 170, 170, 0.5);
            box-sizing: border-box;
          }
        }
        .authcode {
          .item {
            margin-right: 10px;
            align-self: flex-end;
            .inp {
              width: 340px;
            }
          }
          .btn {
            width: 221px;
            height: 40px;
            margin-bottom: 20px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            background: #ff7f00;
            align-self: flex-end;
            border-radius: 4px;
            cursor: pointer;
            &.disable {
              background: #aaa;
            }
          }
        }
        .require {
          p {
            &:before {
              content: "*";
              color: #ff7f00;
            }
          }
          .inp {
            border-color: rgba(244, 154, 35, 0.5);
          }
        }
        .about {
          display: flex;
          .category-box {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            .category {
              position: relative;
              width: 27%;
              padding-left: 17px;
              margin: 0 0 10px 0;
              cursor: pointer;
              &:before {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                top: 50%;
                left: 0;
                margin-top: -6px;
                background: url("https://image.hzhuihe.cn/new_website/contact/unActive.png")
                  center no-repeat;
                background-size: contain;
              }
              &.active {
                &:before {
                  background: url("https://image.hzhuihe.cn/new_website/contact/active.png")
                    center no-repeat;
                  background-size: contain;
                }
              }
            }
          }
        }
        .submit {
          width: 100%;
          height: 40px;
          margin-bottom: 20px;
          line-height: 40px;
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
          background: #ff7f00;
          border-radius: 4px;
          cursor: pointer;
        }
        .label {
          display: flex;
          font-size: 16px;
          color: #333;
          justify-content: center;
          align-items: center;
          #check {
            margin-right: 10px;
          }
          a {
            font-size: 16px;
            color: #333;
          }
        }
      }
      .form-bottom {
        background: #fff2e6;
        border-radius: 0 0 4px 4px;
        .bottom-item {
          padding: 20px 0 25px;
          margin-right: 109px;
          text-align: center;
          .icon {
            display: block;
            width: 30px;
            height: 30px;
            margin: 0 auto 9px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #141414;
          }
        }
      }
    }
  }
}
</style>
