<!--  -->
<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  },
  watch: {
    "$route": () => {
      // document.documentElement.scrollTop = 0
      // console.log(document.documentElement.scrollTop);
      window.scrollTo({
        top: 0,
        // behavior: "smooth"
      })
    }
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
</style>