/*
 * @Autor: swordman
 * @Date: 2022-06-22 10:23:34
 * @LastEditors: swordman
 * @LastEditTime: 2022-06-22 16:06:14
 * @description: 
 */
export const marketPlatform = [
    {
        href: '/vms',
        name: 'VMS'
    },
    {
        href: '/edj',
        name: 'e店佳'
    },
    {
        href: '/kqt',
        name: '快渠通'
    },
    {
        href: '/sxh',
        name: '随心惠'
    }
]

export const plans = [
    {
        href: '/kxsm',
        name: '开箱扫码'
    },
    {
        href: '/sqyx',
        name: '店主社区'
    },
    {
        href: '/clzj',
        name: '陈列自检'
    },
    {
        href: '/ydxp',
        name: '业代巡拍'
    },
    {
        href: '/ddhx',
        name: '到店核销'
    },
    {
        href: '/kgyl',
        name: '开盖有礼'
    },
    {
        href: '/yylx',
        name: '壹元乐享'
    }
]

export const about = [
    {
        href: '/about',
        name: '公司介绍'
    },
    {
        href: '/news',
        name: '新闻资讯'
    },
    {
        href: 'https://www.zhipin.com/gongsi/73c503f966ac1a5a1XJ80927?ka=company-intro',
        name: '加入我们'
    }
]

export const pathName = {
    '/': '首页',
    '/index': '首页',
    '/edj': 'e店佳',
    '/vms': 'VMS',
    '/kqt': '快渠通',
    '/sxh': '随心惠',
    '/case': '客户案例',
    '/about': '公司介绍',
    '/news': '新闻资讯',
    '/kxsm': '开箱扫码',
    '/clzj': '陈列自检',
    '/ydxp': '业代巡拍',
    '/ddhx': '到店核销',
    '/sqyx': '店主社区',
    '/yylx': '壹元乐享',
    '/kgyl': '开盖有礼',
    '/contract': '立即咨询',
    '/newsDetail': '资讯详情'
}