/*
 * @Autor: swordman
 * @Date: 2022-06-22 10:23:34
 * @LastEditors: swordman
 * @LastEditTime: 2022-06-22 18:15:12
 * @description: 
 */
import Vue from 'vue'
import App from './App.vue'
import router from "./router/index"
import MetaInfo from 'vue-meta-info'

Vue.config.productionTip = false
// router.beforeEach((to, form, next) => {
//   if (to.meta.title) {
//     console.log(form);
//     document.title = to.meta.title
//   }
//   next()
// })

Vue.use(MetaInfo)
new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('custom-render-trigger'))
    // document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
