<template>
  <div id="app">
    <Nav :is-transparent="false" />
    <!-- banner -->
    <Banner
      source-url="https://image.hzhuihe.cn/new_website/sqyx/banner.png"
      title="店主社区"
      :sub-title="subTitle"
      alt-text="店主社区"
    />
    <!-- 适应品牌需求 -->
    <!-- <PPXQ :list="list"/> -->
    <div class="ppxq">
      <div class="ppxq-title">适用品牌需求</div>
      <div class="flex ppxq-content">
        <div class="img-box">
          <img src="https://image.hzhuihe.cn/new_website/sqyx/1.png" alt="" />
        </div>
        <div class="img-box special">
          <img src="https://image.hzhuihe.cn/new_website/sqyx/2.png" alt="" />
        </div>
        <div class="img-box m0">
          <img src="https://image.hzhuihe.cn/new_website/sqyx/3.png" alt="" />
        </div>
      </div>
      <div class="flex ppxq-content">
        <div class="item-text">实现品牌与终端<br />“点对点”触达</div>
        <div class="item-text">提高品牌曝光度</div>
        <div class="item-text m0">提升品牌活动参与率</div>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="jjfa">
      <div class="title">解决方案</div>
      <div class="flex jjfa-content">
        <div class="jjfa-content_item">
          <img
            src="https://image.hzhuihe.cn/new_website/sqyx/4.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text">品牌营销视频策划制作</span>
        </div>
        <div class="jjfa-content_item">
          <img
            src="https://image.hzhuihe.cn/new_website/sqyx/5.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text">品牌营销信息在线推广</span>
        </div>
        <div class="jjfa-content_item m0">
          <img
            src="https://image.hzhuihe.cn/new_website/sqyx/6.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text">品牌私域平台线上引流</span>
        </div>
      </div>
    </div>
    <!-- 方案优势 -->
    <div class="fays">
      <div class="title">方案优势</div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://image.hzhuihe.cn/new_website/sqyx/7.png" alt="" />
        </div>
        <div class="item-right">
          <span class="bold">海量流量支撑</span>
          <span>150W+B端门店入驻，月活120W+</span>
        </div>
      </div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://image.hzhuihe.cn/new_website/sqyx/8.png" alt="" />
        </div>
        <div class="item-right">
          <span class="bold">渠道精准营销</span>
          <span>按区域、按门店类型等指标精准触达目标门店</span>
        </div>
      </div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://image.hzhuihe.cn/new_website/sqyx/9.png" alt="" />
        </div>
        <div class="item-right">
          <span class="bold">用户粘性保障</span>
          <span>原创内容引流及平台互动奖励提高用户粘性</span>
        </div>
      </div>
    </div>
    <!-- 方案保障 -->
    <FABZ />
    <!-- 更多方案 -->
    <more-case></more-case>
    <Bottom />
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue"
import Banner from "@/components/Banner.vue"
// import PPXQ from '@/components/PPXQ.vue'
import FABZ from "@/components/FABZ.vue"
import MoreCase from "@/components/MoreCase.vue"
import Bottom from "@/components/Bottom.vue"
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "SQYX",
  components: {
    Nav,
    Banner,
    // PPXQ,
    FABZ,
    MoreCase,
    Bottom,
  },
  data() {
    return {
      subTitle:
        "为品牌方提供市场铺货前的在线营销服务，满足品牌曝光、活动预\n热到商城引流的一站式营销需求。",
      menuId: "2",
      list: [
        {
          icon: "https://image.hzhuihe.cn/new_website/sqyx/1.png",
          desc: "实现品牌与终端 \n“点对点”触达",
          special: true,
        },
        {
          icon: "https://image.hzhuihe.cn/new_website/sqyx/2.png",
          desc: "提高品牌曝光度",
        },
        {
          icon: "https://image.hzhuihe.cn/new_website/sqyx/3.png",
          desc: "提升品牌活动参与率",
        },
      ],
    }
  },
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
#app {
  padding-top: 88px;
  .title {
    text-align: center;
    font-size: 46px;
    color: #141414;
  }
  .jjfa {
    padding: 50px 0 15px;
    background: #f7f9fa;
    .title {
      margin-bottom: 60px;
    }
    .jjfa-content {
      margin-bottom: 50px;
      &_item {
        text-align: center;
        margin-right: 50px;
        &-img {
          display: block;
          width: 410px;
          height: 260px;
          margin-bottom: 20px;
          background: #ffffff;
          img {
            display: block;
            width: 100%;
          }
        }
        &-text {
          position: relative;
          width: 350px;
          padding-left: 20px;
          font-size: 22px;
          font-weight: 400;
          color: #333333;
          box-sizing: border-box;
          &.text-left {
            text-align: left;
          }
          &:before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            top: 14px;
            left: 0;
            background: #333;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .ppxq {
    padding: 40px 0 84px;
    .ppxq-title {
      margin-bottom: 88px;
      text-align: center;
      font-size: 46px;
      color: #141414;
    }
    .ppxq-content {
      padding: 0 480px;
      margin-bottom: 20px;
      text-align: center;
      font-size: 22px;
      color: #333;
      justify-content: space-between;
      box-sizing: border-box;
      .img-box {
        width: 33.3%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.special {
          img {
            height: 90%;
          }
        }
      }
      .item-text {
        width: 33.3%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 57px;
        white-space: nowrap;
      }
      // &_item {
      //     margin-right: 135px;
      //     text-align: center;
      //     font-size: 22px;
      //     color: #333;
      //     .item-icon {
      //         width: 197px;
      //         align-items: flex-end;
      //         margin: 0 auto 27px;
      //         img {
      //             display: block;
      //             max-width: 100%;
      //             margin:0 auto;
      //             align-self: center;
      //         }
      //         &.special {
      //             margin-bottom: 54px;
      //             img {
      //                 height: 53px;

      //             }
      //         }
      //         &.special2 {
      //             margin-bottom: 40px;
      //             img {
      //                 height: 121px;
      //                 align-self: flex-end;
      //             }
      //         }
      //         &.special3 {
      //             margin-bottom: 26px;
      //             img {
      //                 height: 105px;
      //                 align-self: flex-end;
      //             }
      //         }

      //     }
      //     .item-text {
      //         display: flex;
      //         align-items: center;
      //         justify-content: center;
      //         height: 57px;
      //         white-space: pre-line;
      //     }
      // }
    }
  }
  .fays {
    padding: 50px 0;
    .title {
      margin-bottom: 50px;
    }
    .fays-item {
      margin-bottom: 26px;
      .item-left {
        width: 80px;
        height: 80px;
        background: #ffe8c7;
        align-items: center;
        img {
          display: block;
          max-width: 100%;
        }
      }
      .item-right {
        position: relative;
        width: 972px;
        height: 80px;
        padding-left: 30px;
        line-height: 80px;
        font-size: 22px;
        color: #333;
        background: #fffbf7;
        letter-spacing: 1px;
        box-sizing: border-box;
        &:before {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          margin-top: -4px;
          top: 50%;
          left: 15px;
          background: #333;
          border-radius: 50%;
        }
        .bold {
          margin-right: 20px;
          font-size: 26px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
