<template>
  <div id="app">
    <Nav :is-transparent="false"></Nav>
    <Banner
      source-url="https://image.hzhuihe.cn/new_website/video/vms.mp4"
      :isImg="false"
      title="VMS 品牌营销管理平台"
      :sub-title="subTitle"
      alt-text="VMS品牌营销管理平台"
    />
    <div class="flex title-list">
      <div
        :class="[
          'flex title-item',
          { active: currentIndex === index },
          { m0: index == tabList.length - 1 },
        ]"
        v-for="(item, index) in tabList"
        :key="index"
        @click="handleChange(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="flex wrapper wrapper1" id="id1" ref="wrapper1">
      <div class="flex desc-box">
        <div class="desc-title">数字化营销活动</div>
        <div class="desc-text">
          同频管理多档营销活动，覆盖活动新建、配置、优化到复盘全生命周期。
        </div>
        <div class="flex desc-icon">
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/1-1.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>活动配置管理</p>
          </div>
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/1-2.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>二维码规范管理</p>
          </div>
          <div class="desc-icon_box m0">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/1-3.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>优惠券管理</p>
          </div>
        </div>
      </div>
      <img
        class="pic"
        src="https://image.hzhuihe.cn/new_website/vms/1.png"
        alt="VMS"
      />
    </div>
    <div class="flex bg-theme-light wrapper wrapper2" id="id2" ref="wrapper2">
      <img
        class="pic"
        src="https://image.hzhuihe.cn/new_website/vms/2.png"
        alt="VMS"
      />
      <div class="flex desc-box">
        <div class="desc-title">实时数据监控</div>
        <div class="desc-text">
          提供覆盖多活动、多维度的实时数据分析看板、自动化报表等服务，实现全链路数据追踪。
        </div>
        <div class="flex desc-icon">
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/2-1.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>终端陈列看板</p>
          </div>
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/2-2.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>客户签约记录</p>
          </div>
          <div class="desc-icon_box m0">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/2-3.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>客户奖励明细</p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex wrapper wrapper3" id="id3" ref="wrapper3">
      <div class="flex desc-box">
        <div class="desc-title">资金账户监管</div>
        <div class="desc-text">
          完整的资金管控体系，对每一笔资金的变动进行实时记录，满足企业对资金对账、追溯、分析的需求。
        </div>
        <div class="flex desc-icon">
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/3-1.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>资金流水监控</p>
          </div>
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/3-2.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>账户充值/充退</p>
          </div>
          <div class="desc-icon_box m0">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/3-3.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>奖励发放校验</p>
          </div>
        </div>
      </div>
      <img
        class="pic"
        src="https://image.hzhuihe.cn/new_website/vms/3.png"
        alt="VMS"
      />
    </div>
    <div class="flex bg-theme-light wrapper wrapper4" id="id4" ref="wrapper4">
      <img
        class="pic"
        src="https://image.hzhuihe.cn/new_website/vms/4.png"
        alt="VMS"
      />
      <div class="flex desc-box">
        <div class="desc-title">门店客户管理</div>
        <div class="desc-text">
          覆盖品牌传统渠道营销全链路的组织架构管理，形成权限分明、安全高效的管理体系。
        </div>
        <div class="flex desc-icon">
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/4-1.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>门店管理</p>
          </div>
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/4-2.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>员工管理</p>
          </div>
          <div class="desc-icon_box m0">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/4-3.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>组织架构管理</p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex wrapper wrapper5" id="id5" ref="wrapper5">
      <div class="flex desc-box">
        <div class="desc-title">风险管理控制</div>
        <div class="desc-text">
          基于惠合黑白名单管理体系和用户行为监控机制，严格控制风险范围，多重保障活动安全。
        </div>
        <div class="flex desc-icon">
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/5-1.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>黑名单管理</p>
          </div>
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/5-2.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>用户行为监控</p>
          </div>
          <div class="desc-icon_box m0">
            <img
              src="https://image.hzhuihe.cn/new_website/vms/5-3.png"
              alt="VMS"
              class="icon-box_img"
            />
            <p>风险记录</p>
          </div>
        </div>
      </div>
      <img
        class="pic"
        src="https://image.hzhuihe.cn/new_website/vms/5.png"
        alt="VMS"
      />
    </div>

    <!-- 四端在线start -->
    <SDZX></SDZX>
    <!-- 平台保障start -->
    <PTBZ></PTBZ>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue"
import Banner from "@/components/Banner.vue"
import Bottom from "@/components/Bottom.vue"
import SDZX from "@/components/SDZX.vue"
import PTBZ from "@/components/PTBZ.vue"
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/dist/css/swiper.css";
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "App",
  components: {
    Nav,
    Banner,
    Bottom,
    SDZX,
    PTBZ,
    // swiper,
    // swiperSlide,
  },
  data() {
    return {
      subTitle:
        "为品牌方提供覆盖活动配置、数据追踪、资金监管到风险控制的全\n生命周期管理服务。数据驱动品牌营销活动精准投放、倍数转化。",
      bannerList: [
        "https://image.hzhuihe.cn/new_website/vmsBanner.png",
        "https://image.hzhuihe.cn/new_website/vmsBanner.png",
      ],
      swiperOptions: {
        autoplay: false,
        speed: 1000,
        loop: false,
        // Some Swiper option/callback...
      },
      tabList: [
        "数字化营销活动",
        "实时数据监控",
        "资金账户监管",
        "门店客户管理",
        "风险控制",
      ],
      currentIndex: 0,
    }
  },
  methods: {
    handleChange(index) {
      this.currentIndex = index
      let offsetTop = this.$refs[`wrapper${index + 1}`].offsetTop - 88
      try {
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        })
      } catch (e) {
        window.scrollTo(0, offsetTop)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import url("~@/assets/global.less");
#app {
  padding-top: 88px;
}
.title-list {
  padding-top: 97px;
  margin-bottom: 2.8vw;
  .title-item {
    position: relative;
    width: 13vw;
    height: 5.5vw;
    margin-right: 3vw;
    padding-bottom: 0.6vw;
    font-size: 1vw;
    font-weight: 400;
    color: #333333;
    background: url("https://image.hzhuihe.cn/new_website/tabBg.png") center top
      no-repeat;
    background-size: 100% 100%;
    background-clip: content-box;
    border-radius: 4px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
    justify-content: center;
    align-items: center;
    &:before {
      content: "";
      position: absolute;
      top: 20px;
      width: 30px;
      height: 4px;
      background: #333;
      border-radius: 2px;
    }
    &.active {
      color: #fff;
      box-shadow: none;
      background: url("https://image.hzhuihe.cn/new_website/tabActiveBg.png")
        top center no-repeat;
      background-size: 100% 100%;
      background-clip: border-box;
      &:before {
        background: #fff;
      }
    }
  }
}
.wrapper {
  align-items: center;
  .desc-box {
    width: 695px;
    // margin: 0 2vw;
    flex-direction: column;
    font-size: 22px;
    align-items: center;
    .desc-title {
      margin-bottom: 2vw;
      font-size: 2vw;
      color: #141414;
    }
    .desc-text {
      margin-bottom: 2vw;
    }
    .desc-icon {
      justify-content: space-around;
      .desc-icon_box {
        margin-right: 4vw;
        text-align: center;
        .icon-box_img {
          width: 76px;
          margin-bottom: 1vw;
        }
        p {
          font-size: 1vw;
        }
      }
    }
  }
  .pic {
    width: 705px;
  }
  &.wrapper1 {
    margin-bottom: 2.2vw;
    .desc-text {
      white-space: nowrap;
    }
  }
  &.wrapper2 {
    padding: 20px 0;
  }
  &.wrapper3 {
    padding: 30px 0;
  }
  &.wrapper4 {
    padding: 40px 0 20px;
  }
  &.wrapper5 {
    padding: 48px 0 80px;
  }
}
</style>
