<template>
    <div class="banner">
        <img :src="sourceUrl" :alt="altText" v-if="isImg" />
        <video
            v-else
            :src="sourceUrl"
            muted
            autoplay="autoplay"
            loop="loop"
            class="video"
        >您的浏览器不支持 video 标签。</video>
        <div class="mask">
            <div class="content">
                <div class="title">{{ title }}</div>
                <div class="sub-title">{{ subTitle }}</div>
                <div class="btn" @click="handleClick">立即咨询</div>
            </div>
        </div>
        <div class="img-btn" :style="btnStyle" @click="handleClick"></div>
    </div>
</template>
<script>
import { pathName } from '@/utils/enum.js'
import { getPath2name } from '@/utils/utils.js'
export default {
    name: 'Banner',
    props: {
        sourceUrl: {
            type: String,
            default: ''
        },
        altText: {
            type: String,
            default: '惠合科技'
        },
        isImg: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: '惠合科技'
        },
        subTitle: {
            type: String,
            default: ''
        },
        isHref: {
            type: Boolean,
            default: true
        },
        btnStyle: {
            type: Object,
            default: () => {
                return {
                    left: '16%',
                    bottom: '26%'
                }
            }
        }
    },
    methods: {
        handleClick() {
            if (this.isHref) {
                // window.location.href = "/contact"
                this.$router.push(`/contact?from=${pathName[getPath2name()]}&moudle=banner区`)
            }
        }
    }
}
</script>
<style lang="less" scoped>
.banner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 33.8%;
    cursor: pointer;
    overflow: hidden;
    img {
        display: block;
        width: 100%;
    }
    .video {
        width: 100%;
    }
    .mask {
        display: flex;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        align-items: center;
        z-index: 2;
        .content {
            margin-left: 308px;
            color: #ffffff;
            .title {
                margin-bottom: 40px;
                font-size: 58px;
                font-weight: 600;
                letter-spacing: 3px;
            }
            .sub-title {
                margin-bottom: 60px;
                font-size: 22px;
                line-height: 36px;
                font-weight: 400;
                white-space: pre-line;
            }
            .btn {
                width: 206px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                background: #ff7f00;
                font-size: 28px;
                font-weight: 400;
                letter-spacing: 1px;
                border-radius: 8px;
            }
        }
    }
    .img-btn {
        position: absolute;
        width: 210px;
        height: 55px;
        border-radius: 8px;
        cursor: pointer;
    }
}
</style>