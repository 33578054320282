export const getUrlParms = (name) => {
    var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if(r!=null) return unescape(r[2]);
    return null;
}

// 时间转换
export const formatTime = (number, format) => {
	if (!number) {
		return '-'
	}
	var formateArr = ['Y', 'M', 'D', 'h', 'm', 's']
	var returnArr = []

	// eslint-disable-next-line no-undef
	var date = new Date(number)

	// 数据转化
	function formatNumber(n) {
		return n > 9 ? n : '0' + n
	}

	returnArr.push(date.getFullYear())
	returnArr.push(formatNumber(date.getMonth() + 1))
	returnArr.push(formatNumber(date.getDate()))
	returnArr.push(formatNumber(date.getHours()))
	returnArr.push(formatNumber(date.getMinutes()))
	returnArr.push(formatNumber(date.getSeconds()))
	for (var i = 0; i < returnArr.length; i++) {
		format = format.replace(formateArr[i], returnArr[i])
	}
	return format
}

export const getPath2name = () => {
	const { pathname } = window.location
	// const index = pathname.indexOf('.')
	// return index > -1 ? pathname.substring(0, index) : pathname
	return pathname
}