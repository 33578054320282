<template>
    <div class="khal">
        <div class="khal-title">客户案例</div>
        <div class="flex khal-content">
            <div class="khal-content_text">
                <div class="text-title">{{title}}</div>
                <div class="text-detail" v-if="description" v-html="description"></div>
                <!-- <div class="text-detail" v-else v-html="detail"></div> -->
                <!-- <a href="javascript: void(0)" class="btn">了解详情</a> -->
                <router-link :to="{ name:'newsDetail', query: { id: +newsId } }" class="btn">了解详情</router-link>
            </div>
            <div class="khal-content_img">
                <img :src="picUrl" alt="客户案例">
            </div>
        </div>
    </div>
</template>
<script>
// import {doPost} from '@/utils/request'
export default {
    name: 'CustomerCase',
    props: {
        menuId: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        picUrl: {
            type: String,
            default: ''
        },
        newsId: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            // id: '',
            // title: '客户案例标题',
            // detail: '',
            // picUrl: '',
            // description: ''
        }
    },
    mounted () {
        // this.getCase()
    },
    methods: {
        // 获取客户案例
        // getCase () {
        //     doPost('pms/officialWebsiteArticle/customerCase.json', {
        //         category: '客户案例',
        //         menuId: this.menuId
        //     }).then(res => {
        //         this.id = res.id
        //         this.title = res.title
        //         this.detail = res.detail
        //         this.description = res.description
        //         this.picUrl = res.picUrl
        //     })
        // }
    }
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
.khal {
    padding: 33px 0 70px;
    background: #f7f9fa;
    .khal-title {
        margin-bottom: 84px;
        text-align: center;
        font-size: 46px;
        color: #141414;
    }
    .khal-content {
        align-items: flex-start;
        .khal-content_text {
            width: 695px;
            margin-right: 80px;
            .text-title {
                width: 100%;
                white-space: nowrap;
                margin-bottom: 48px;
                font-size: 36px;
                color: #141414;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .text-detail {
                margin-bottom: 46px;
                font-size: 22px;
                color: #333;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                line-clamp: 5;
                -webkit-box-orient: vertical;
                word-break:break-all;
                word-wrap:break-word;
            }
            .btn {
                width: 206px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                color: #FEFEFE;
                font-size: 28px;
                background: #ff7f00;
                letter-spacing: 1px;
                border-radius: 8px;
            }
        }
        .khal-content_img {
            width: 620px;
            max-height: 370px;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
            }
        }
    }
}
</style>