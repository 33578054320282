// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import axios from 'axios'
import { getPramsData } from '@/utils/getPramsData'
import PROJECT_CONFIG from '@/config/config'
import LoadAnimation from '@/load/loadAnimation'
import toast from './toast'
import {
	VueAxios
} from './axios'
// 创建 axios 实例
const service = axios.create({
  baseURL: PROJECT_CONFIG.dataServer,
  timeout: 300000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 403) {
      toast.showToast(data.message)
    }
  }
  return Promise.reject(error)
}

// response interceptor
service.interceptors.response.use(res => res, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}
const loadAni = new LoadAnimation()
const doPost = (url, params, loading = true) => new Promise((resolve, reject) => {
  // 需要 loading 时开启 loading
  loading && loadAni.show()
  service({
    url,
    method: 'post',
    data: getPramsData(params)
  }).then(res => {
    if (res.status !== 200) {
      toast.showToast('服务器异常，请联系：400-181-6322')
      return reject(new Error())
    }
    if (res.data.status === 1) {
      resolve(res.data.data)
    } else {
      toast.showToast(res.data.errorMsg)
      reject(new Error(res.data.errorMsg))
    }
  }).catch(err => {
    toast.showToast(err.response.status && err.response.status !== 200
      ? `系统繁忙，请联系：400-181-6322`
      : err.response.data.message)
    reject(new Error(err))
  }).finally(() => {
    // 关闭loading
    loading && loadAni.hide()
  })
})

export {
  installer as VueAxios,
  service as axios,
  doPost
}
