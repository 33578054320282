<template>
    <div id="app">
        <Nav :is-transparent="false"></Nav>
        <div class="flex main">
            <div class="content border">
                <div class="breadcrumb" v-if="category == '新闻资讯'">关于我们>{{ category }}</div>
                <div class="breadcrumb" v-else>{{ category }}</div>
                <div class="news-title">{{ title }}</div>
                <div class="news-time">
                    <span class="time">{{ createTime }}</span>
                    <span
                        v-for="(item, index) in labelList"
                        class="label"
                        :key="index"
                    >{{ item.labelName }}</span>
                </div>
                <article class="news-content" v-if="detail" v-html="detail" id="detail"></article>
                <div class="flex">
                    <div class="btn pre" @click="handleChange('pre')">上一篇</div>
                    <div class="btn" @click="handleChange('next')">下一篇</div>
                </div>
            </div>
            <div class="recommend border">
                <div class="recommend-title">推荐阅读</div>
                <ul class="recommend-list">
                    <li
                        class="recommend-item"
                        v-for="item in recommendList"
                        @click="handleClick(item.id)"
                        :key="item.id"
                    >
                        <div class="title">{{ item.title }}</div>
                        <div class="date">{{ item.releaseTime }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <Bottom></Bottom>
    </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Bottom from "@/components/Bottom.vue";
import { getUrlParms, formatTime } from '@/utils/utils'
import { doPost } from '@/utils/request'
import { title, description, keywords } from './global'
export default {
    metaInfo: {
        title: title,
        meta: [
            {
                name: 'keywords',
                content: keywords
            },
            {
                name: 'description',
                content: description
            }
        ]

    },
    name: "App",
    components: {
        Nav,
        Bottom
    },
    data() {
        return {
            currentId: '',
            category: '新闻资讯',
            title: '新闻标题',
            createTime: '2021-09-24',
            detail: '内容',
            recommendList: [],
            labelList: []
        }
    },
    mounted() {
        this.currentId = getUrlParms('id')
        this.getDetail()
        // this.getRecommendList()
    },
    updated(){

        this.updateImgWidth()
    },
    methods: {
        getDetail() {
            doPost('pms/officialWebsiteArticle/doGetByNoLogin.json', this.currentId).then(res => {
                this.title = res.title
                this.category = res.category
                this.createTime = formatTime(res.createTime, 'Y-M-D')
                this.detail = res.detail
                this.labelList = res.labels
                this.getRecommendList(res.category)
            })
        },
        getRecommendList(category) {
            doPost('pms/officialWebsiteArticle/doQueryAll.json', {
                recommendStatus: 1,
                category: category
            }).then(res => {
                this.recommendList = res
                this.recommendList.map(value => {
                    value.releaseTime = formatTime(value.showReleaseTime, 'Y-M-D')
                })
            })
        },
        handleClick(id) {
            // window.location.href = `/newsDetail.html?id=${id}`
            this.$router.push({
                path: '/newsDetail',
                query: {
                    id: id
                }
            })
        },
        // 上下篇
        handleChange(type) { // pre 上一篇  next 下一篇
            let theUrl = type === 'pre' ? 'pms/officialWebsiteArticle/doGetPre.json' : 'pms/officialWebsiteArticle/doGetAfter.json'
            doPost(theUrl, this.currentId).then(res => {
                res && this.handleClick(res.id)
            })
        },
        updateImgWidth(){
            let box = document.getElementById("detail");
            let images = box.getElementsByTagName("img");
            for(let i = 0; i<images.length ;i++){
                images[i].style.width = "100%"
            }
        }
    }
}
</script>

<style lang='less' scoped>
@import url("~@/assets/global.less");
#app {
    padding-top: 88px;
    background: #f5f5f5;
    .main {
        padding: 20px 0 30px;
        align-items: flex-start;
        .border {
            border-radius: 4px;
            box-shadow: 0px 3px 8px 0px rgba(156, 156, 156, 0.3);
            box-sizing: border-box;
        }
        .content {
            width: 889px;
            margin-right: 30px;
            padding: 40px 80px 20px;
            background: #fff;
            border-radius: 4px;
            .breadcrumb {
                margin-bottom: 35px;
                font-size: 16px;
                color: @primary-color;
            }
            .news-title {
                width: 100%;
                margin-bottom: 17px;
                font-size: 30px;
                font-weight: 500;
                color: #333333;
            }
            .news-time {
                margin-bottom: 17px;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                .time {
                    margin-right: 50px;
                }
                .label {
                    padding: 0 10px;
                    margin-right: 10px;
                    color: #ff7f00;
                    background: #ffecda;
                    border-radius: 2px;
                }
            }
            .news-content {
                margin-bottom: 48px;
                word-break: break-all;
                word-wrap: break-word;
                img {
                    max-width: 100%;
                }
            }
            .btn {
                width: 66px;
                height: 26px;
                text-align: center;
                background: #dddddd;
                font-size: 16px;
                color: #333;
                border-radius: 4px;
                cursor: pointer;
                &.pre {
                    margin-right: 20px;
                }
            }
        }
        .recommend {
            width: 379px;
            background: #fff;
            overflow: hidden;
            box-sizing: border-box;
            .recommend-title {
                width: 100%;
                height: 80px;
                padding: 0 20px;
                line-height: 80px;
                font-size: 26px;
                color: #ffffff;
                background: @primary-color;
                box-sizing: border-box;
            }
            .recommend-list {
                width: 100%;
                height: auto;
                padding: 0 20px;
                box-sizing: border-box;
                .recommend-item {
                    padding: 20px 0;
                    color: #333;
                    font-size: 22px;
                    border-bottom: 1px solid #ddd;
                    cursor: pointer;
                    &:last-child {
                        border-bottom: none;
                    }
                    .title {
                        margin-bottom: 12px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .date {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
</style>
