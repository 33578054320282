<template>
  <div id="app">
    <Nav :is-transparent="false" />
    <!-- banner -->
    <Banner
      source-url="https://image.hzhuihe.cn/new_website/kxsm/banner.png"
      title="开箱扫码"
      :sub-title="subTitle"
      alt-text="开箱扫码"
    />
    <!-- 适应品牌需求 -->
    <PPXQ :list="list" />
    <!-- 解决方案 -->
    <div class="jjfa">
      <div class="title">解决方案</div>
      <div class="flex jjfa-content">
        <div class="jjfa-content_item">
          <img
            class="jjfa-content_item-img"
            src="https://image.hzhuihe.cn/new_website/kxsm/step1.png"
            alt
          />
          <span class="jjfa-content_item-text">定制奖励标准及活动规则</span>
        </div>
        <div class="jjfa-content_item m0">
          <img
            class="jjfa-content_item-img"
            src="https://image.hzhuihe.cn/new_website/kgyl/6.png"
            alt
          />
          <span class="jjfa-content_item-text">活动规则配置并生成码包</span>
        </div>
      </div>
      <div class="flex jjfa-content">
        <div class="jjfa-content_item">
          <img
            class="jjfa-content_item-img"
            src="https://image.hzhuihe.cn/new_website/kxsm/step3.png"
            alt
          />
          <span class="jjfa-content_item-text">门店购入箱内赋码商品</span>
        </div>
        <div class="jjfa-content_item">
          <img
            class="jjfa-content_item-img"
            src="https://image.hzhuihe.cn/new_website/kxsm/step4.png"
            alt
          />
          <span class="jjfa-content_item-text">开箱扫码在线领取红包</span>
        </div>
        <div class="jjfa-content_item m0">
          <img
            class="jjfa-content_item-img"
            src="https://image.hzhuihe.cn/new_website/kxsm/step5.png"
            alt
          />
          <span class="jjfa-content_item-text">数据追踪分析活动效果</span>
        </div>
      </div>
    </div>
    <!-- 千人千面 -->
    <QRQM
      :title="qTtitle"
      :content-list="contentList"
      :isClick="false"
      :isShowSubTitle="false"
      :isShowBg="false"
    />
    <!-- 客户案例 -->
    <customer-case
      :menu-id="menuId"
      :title="caseTitle"
      :description="caseDesc"
      news-id="38"
      pic-url="https://image.hzhuihe.cn/new_website/khal/kxsm.png"
    ></customer-case>
    <!-- 方案保障 -->
    <FABZ />
    <!-- 更多方案 -->
    <more-case :menu-id="menuId"></more-case>
    <Bottom />
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue"
import Banner from "@/components/Banner.vue"
import PPXQ from "@/components/PPXQ.vue"
import QRQM from "@/components/SDZX.vue"
import CustomerCase from "@/components/CustomerCase.vue"
import FABZ from "@/components/FABZ.vue"
import MoreCase from "@/components/MoreCase.vue"
import Bottom from "@/components/Bottom.vue"
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "CLZJ",
  components: {
    Nav,
    Banner,
    PPXQ,
    QRQM,
    CustomerCase,
    FABZ,
    MoreCase,
    Bottom,
  },
  data() {
    return {
      subTitle:
        "基于“一物一码”技术，通过随机红包、集卡兑奖、阶梯返利等\n多样化奖励形式，提升终端进货率、开箱率和上架率。",
      caseTitle: "惠合科技X世界500强水饮品牌",
      caseDesc:
        "该品牌每天在华饮用量达1.5亿杯，为中国消费者提供超过15个品牌50多种饮料产品。而在部分区域，该品牌一直存在渠道价格不统一、渠道窜货的现象。通过与惠合合作“开箱扫码-区域奖励”项目，在半年时间内统一了渠道价格，活动参与门店数超20W家，活动期间销售额同比增长15%。",
      menuId: "1",
      detail: "",
      list: [
        {
          icon: "https://image.hzhuihe.cn/new_website/kxsm/1.png",
          desc: "抢占终端库存\n挤压竞品空间",
        },
        {
          icon: "https://image.hzhuihe.cn/new_website/kxsm/2.png",
          desc: "提升产品渗透\n推动新品入市",
        },
        {
          icon: "https://image.hzhuihe.cn/new_website/kxsm/3.png",
          desc: "统一渠道价格\n降低调价影响",
        },
        {
          icon: "https://image.hzhuihe.cn/new_website/kxsm/4.png",
          desc: "拓展薄弱区域\n开辟新兴市场",
        },
      ],
      qTtitle: "“千店千面”奖励机制",
      contentList: [
        {
          title: "定额随机红包",
          desc:
            "适用于“阶段性门店刺激或提价后价格缓冲”的品牌需求。可根据营销策略，按区域、时间段、SKU任意投放奖励金额。",
        },
        {
          title: "阶梯返利",
          desc:
            "适用于“抢占终端库存、提升本品销量”的品牌需求。可根据历史数据，合理制定进货梯度标准，实现销量考核从渠道转移到门店。",
        },
        {
          title: "集卡兑奖",
          desc:
            "适用于“以老带新、提升产品渗透率”的品牌需求。门店进货扫码可获得不同类型SKU奖励，集齐N种SKU可获得额外奖励。",
        },
        {
          title: "任务奖励",
          desc:
            "适用于“完善渠道体系，精准二次营销”的品牌需求。通过设置进货扫码奖励，引导门店在领取奖励前完善门店信息，为二次营销提供数据基础。",
        },
      ],
    }
  },
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
#app {
  padding-top: 88px;
  .jjfa {
    padding: 50px 0 15px;
    background: #f7f9fa;
    .title {
      margin-bottom: 60px;
      text-align: center;
      font-size: 46px;
      color: #141414;
    }
    .jjfa-content {
      margin-bottom: 50px;
      &_item {
        text-align: center;
        margin-right: 50px;
        &-img {
          display: block;
          width: 410px;
          height: 260px;
          margin-bottom: 20px;
          img {
            display: block;
            width: 100%;
          }
        }
        &-text {
          position: relative;
          padding-left: 20px;
          font-size: 22px;
          font-weight: 400;
          color: #333333;
          box-sizing: border-box;
          &.text-left {
            text-align: left;
          }
          &:before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            top: 14px;
            left: 0;
            background: #333;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>
