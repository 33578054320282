<template>
  <div :class="['sdzx bg-theme-light', { 'no-bg': !isShowBg }]">
    <div class="sdzx-title">{{ title }}</div>
    <div class="sdzx-text" v-if="isShowSubTitle">{{ subTitle }}</div>
    <div class="flex sdzx-box">
      <div
        class="sdzx-item"
        v-for="(item, index) in contentList"
        :key="index"
        @click="handleClick(item)"
      >
        <div class="flex sdzx-item_title">{{ item.title }}</div>
        <div class="sdzx-item_text">{{ item.desc }}</div>
      </div>
      <!-- <div class="sdzx-item">
                <div class="flex sdzx-item_title">e店佳</div>
                <div class="sdzx-item_text">面向终端门店的营销活动平台，为门店主提供信息获取、任务执行到奖励领取的全流程服务，与品牌商实现“两端共鸣”。</div>
            </div>
            <div class="sdzx-item">
                <div class="flex sdzx-item_title">快渠通</div>
                <div class="sdzx-item_text">面向品牌销售体系的营销管理平台，为各层级管理者提供覆盖门店、人员、活动到费用的在线管理工具和数字化营销服务。</div>
            </div>
            <div class="sdzx-item m0">
                <div class="flex sdzx-item_title">随心惠</div>
                <div class="sdzx-item_text">面向消费者的营销互动平台，为品牌商承接线上流量，实现多类型C端营销活动的线下落地。</div>
            </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "SDZX",
  props: {
    title: {
      type: String,
      default: "构建“四端在线”营销服务平台",
    },
    subTitle: {
      type: String,
      default:
        "打造传统渠道营销最短路径，实现信息传达、任务管理、费用核销、结果反馈的在线化和智能化。",
    },
    isShowSubTitle: {
      type: Boolean,
      default: true,
    },
    isShowBg: {
      type: Boolean,
      default: true,
    },
    isClick: {
      type: Boolean,
      default: true,
    },
    contentList: {
      type: Array,
      default: () => [
        {
          title: "VMS\n品牌营销管理平台",
          desc:
            "VMS是面向品牌方的营销管理平台，覆盖B/C端营销项目，提供从活动配置、数据追踪、资金监管到风险控制的全生命周期管理服务。",
          pageUrl: "/vms",
        },
        {
          title: "e店佳",
          desc:
            "e店佳是面向终端门店的营销活动平台，可以为门店主提供信息获取、任务执行到奖励领取的全流程服务，与品牌商实现 “两端共鸣”。目前，该平台已经入驻超过150万传统门店。",
          pageUrl: "/edj",
        },
        {
          title: "快渠通",
          desc:
            "快渠通是面向品牌销售体系的营销管理平台，可以为各层级管理者提供覆盖门店、人员、活动到费用的在线管理工具，打造数字化营销管理体系。",
          pageUrl: "/kqt",
        },
        {
          title: "随心惠",
          desc:
            "随心惠是面向消费者的营销互动平台，以优惠券形式，为品牌商承接线上流量，实现多类型C端营销活动的线下落地。目前，该平台已经触达超1亿C端用户。",
          pageUrl: "/sxh",
        },
      ],
    },
  },
  methods: {
    handleClick(item) {
      if (this.isClick) {
        window.location.href = item.pageUrl
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
.sdzx {
  padding: 3vw 0;
  text-align: center;
  background: @bg-color-light
    url("https://image.hzhuihe.cn/new_website/sdzxBg.png") center no-repeat;
  background-size: contain;
  &.no-bg {
    background: none;
  }
  .sdzx-title {
    margin-bottom: 2vw;
    font-size: 2.3vw;
    color: #141414;
  }
  .sdzx-text {
    margin-bottom: 3vw;
    font-size: 22px;
  }
  .sdzx-box {
    align-items: self-end;
    .sdzx-item {
      width: 18vw;
      height: 429px;
      margin-right: 2vw;
      padding-top: 1.5vw;
      letter-spacing: 1px;
      background: url("https://image.hzhuihe.cn/new_website/s-unActive.png")
        bottom center no-repeat;
      background-size: 100% 100%;
      background-clip: content-box;
      filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.2));
      box-sizing: border-box;
      .sdzx-item_title {
        height: 150px;
        padding: 0;
        font-size: 30px;
        color: #141414;
        align-items: center;
        white-space: pre-line;
      }
      .sdzx-item_text {
        padding: 0 30px;
        text-align: left;
        font-size: 22px;
        line-break: anywhere;
      }
      &:hover {
        background: url("https://image.hzhuihe.cn/new_website/s-active.png")
          bottom center no-repeat;
        background-size: 100% 100%;
        background-clip: border-box;
        color: #ffffff;
        .sdzx-item_title {
          color: #fff;
        }
      }
    }
  }
}
</style>
