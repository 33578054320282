<template>
  <div id="app">
    <Nav></Nav>
    <div class="Banner">
      <img
        class="banner_img"
        src="https://image.hzhuihe.cn/new_website/index/banner_new.png"
        alt="惠合科技"
      />
      <div class="banner_text">新闻资讯</div>
    </div>
    <div class="content">
      <div class="content_top hover" @click="openDetail(FirstNew.id)">
        <img class="content_top_img" :src="FirstNew.picUrl" alt />
        <div class="content_top_content">
          <div class="content_top_title">{{ FirstNew.title }}</div>
          <div class="content_top_info">{{ FirstNew.description }}</div>
          <div class="content_btn_list">
            <div class="more">查看全文</div>
            <span>{{ FirstNew.showReleaseTime | formatTime }}</span>
          </div>
        </div>
      </div>
      <div class="content_list">
        <div
          class="content_item hover"
          v-for="item in OtherNews"
          :key="item.id"
          @click="openDetail(item.id)"
        >
          <img class="content_item_img" :src="item.picUrl" alt />
          <div class="content_item_title">{{ item.title }}</div>
          <div class="content_item_time">
            {{ item.showReleaseTime | formatTime }}
          </div>
        </div>
      </div>
      <page :allPages="allPages" @on-change="pageChange" />
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue"
import Bottom from "@/components/Bottom.vue"
import Page from "@/components/Page.vue"
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
import "swiper/dist/css/swiper.css"
import { doPost } from "@/utils/request"
import { formatTime } from "@/utils/utils"
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "App",
  components: {
    Nav,
    Bottom,
    Page,
    // swiper,
    // swiperSlide
  },
  filters: {
    formatTime(t) {
      return formatTime(t, "Y-M-D")
    },
  },
  data() {
    return {
      bannerList: [
        "https://image.hzhuihe.cn/new_website/index/news_banner.jpg",
      ],
      swiperOptions: {
        autoplay: true,
        speed: 1000,
        //   loop: true
        // Some Swiper option/callback...
      },
      allPages: 0,
      OtherNews: [],
      FirstNew: {},
    }
  },
  created() {
    this.List()
  },
  methods: {
    List(currentPage = 1) {
      doPost("pms/officialWebsiteArticle/doQueryWithPageByNoLogin.json", {
        currentPage,
        pageSize: 7,
        category: "新闻资讯",
      }).then((res) => {
        this.FirstNew = res.datalist.shift()
        this.OtherNews = res.datalist
        this.allPages = res.pageCount
      })
    },
    pageChange(page) {
      this.List(page)
    },
    openDetail(id) {
      // location.href = `./newsDetail.html?id=${id}`
      this.$router.push({
        path: "/newsDetail",
        query: {
          id: id,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import url("~@/assets/global.less");
body,
html {
  background: #f5f5f5;
}

div {
  transition: all 0.3s;
}
.Banner {
  min-width: 1400px;
  width: 100%;
  padding-top: 88px;
  position: relative;
  .swiper {
    width: 100%;
  }
  .banner_img {
    min-width: 1400px;
    width: 100%;
    height: 650px;
    display: block;
    object-fit: cover;
  }
  .banner_text {
    position: absolute;
    top: 360px;
    left: 50%;
    width: 100%;
    text-align: center;
    font-size: 58px;
    color: #fff;
    font-weight: bold;
    transform: translateX(-50%);
    letter-spacing: 2px;
  }
}
.content {
  width: 1290px;
  margin: 0 auto;
  padding: 65px 0 72px;
  .content_top {
    width: 100%;
    height: 370px;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    .boxShow();
    .content_top_img {
      width: 646px;
      height: 100%;
      object-fit: cover;
      float: left;
    }
    .content_top_content {
      overflow: hidden;
      padding-left: 20px;
      padding-right: 30px;
    }
    .content_top_title {
      font-size: 22px;
      line-height: 70px;
      .textOverFlow(1);
    }
    .content_top_info {
      font-size: 16px;
      color: #333;
      line-height: 28px;
      height: 140px;
      .textOverFlow(5);
    }
    .content_btn_list {
      display: flex;
      justify-content: space-between;
      margin-top: 124px;
      font-size: #333;
      font-size: 16px;
      .more {
        color: @primary-color;
        text-decoration: underline;
      }
    }
  }

  .content_list {
    width: 100%;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
  }
  .content_item {
    .boxShow();
    overflow: hidden;
    float: left;
    width: 410px;
    height: 366px;
    margin-top: 30px;
    margin-right: 30px;
    font-size: 18px;
    color: #333;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .content_item_img {
      width: 100%;
      height: 234px;
      object-fit: cover;
      display: block;
    }
    .content_item_title {
      margin: 12px;
      line-height: 28px;
      height: 56px;
      font-size: 22px;
      .textOverFlow(2);
    }
    .content_item_time {
      padding: 0 12px;
    }
  }
}

.hover {
  cursor: pointer;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 3px 40px 0px rgba(156, 156, 156, 0.3);
  }
}
@keyframes boost {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(10);
  }
}
</style>
