<template>
     <div class="page_list">
         <button class='item' :disabled='currentPage === 1'  @click="prev">上一页</button>
         <div :class="[
             'item',
             {
                 'item_active': currentPage === 1
             }
         ]" @click='changePage(1)'>1</div>
         <div class='item' v-if="currentPage > 5"  @click="fastPrev">...</div>
         <div class='item' v-if="currentPage === 5" @click='changePage(currentPage - 3)'>{{ currentPage - 3 }}</div>
         <div class='item' v-if="currentPage - 2 > 1" @click='changePage(currentPage - 2)'>{{ currentPage - 2 }}</div>
         <div class='item' v-if="currentPage - 1 > 1" @click='changePage(currentPage - 1)'>{{ currentPage - 1 }}</div>
         <div v-if="currentPage != 1 && currentPage != allPages" class="item item_active">{{ currentPage }}</div>
         <div class='item' v-if="currentPage + 1 < allPages" @click='changePage(currentPage + 1)'>{{ currentPage + 1 }}</div>
         <div class='item' v-if="currentPage + 2 < allPages" @click='changePage(currentPage + 2)'>{{ currentPage + 2 }}</div>
         <div class='item' v-if="allPages - currentPage === 4" @click='changePage(currentPage + 3)'>{{ currentPage + 3 }}</div>
         <div class='item' v-if="allPages - currentPage >= 5" @click="fastNext">...</div>
         <div :class="[
             'item',
             {
                 'item_active': currentPage === this.allPages
             }
         ]" v-if="allPages > 1" @click='changePage(allPages)'>{{allPages }}</div>
         <!-- <div class='item'>3</div>
         <div class='item'>4</div> -->
         <button class='item' :disabled='currentPage === allPages'  @click="next">下一页</button>
     </div>
</template>

<script>

export default {
    name: 'Page',
    props: {
        allPages: {
            type: Number,
            default: 10
        },
        current: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            currentPage: this.current
        }
    },
    mounted() {

    },
    methods: {
        changePage (page) {

            if (this.currentPage != page) {
                this.currentPage = page;
                
                this.$emit('update:current', page);
                this.$emit('on-change', page);
            }
        },
        fastPrev () {

            const page = this.currentPage - 5;
            if (page > 0) {
                this.changePage(page);
            } else {
                this.changePage(1);
            }
        },
        fastNext () {
            const page = this.currentPage + 5;
            if (page > this.allPages) {
                this.changePage(this.allPages);
            } else {
                this.changePage(page);
            }
        },
        prev () {
            const current = this.currentPage;
            if (current <= 1) {
                return false;
            }
            this.changePage(current - 1);
        },
        next () {
            const current = this.currentPage;
            if (current >= this.allPages) {
                return false;
            }
            this.changePage(current + 1);
        },
    }
}
</script>

<style lang='less' scoped>
@import url("~@/assets/global.less");

.page_list{
    display: flex;
    padding-top: 60px;
    justify-content: center;
    .item{
        border-radius: 4px;
        font-size: 16px;
        padding: 1px 10px;
        background: #DDDDDD;
        margin-right: 10px;
        cursor: pointer;
        color: #333;
        user-select: none;
        &:disabled{
            color: #6B6B6B;
        }
    }
    .item_active{
        background: @primary-color;
        color: #fff;
    }
}
</style>
