<template>
  <div class="more-case">
    <div class="title">更多方案</div>
    <div class="banner">
      <swiper
        class="swiper"
        ref="mySwiper"
        :options="swiperOptions"
        v-if="bannerList.length > 0"
      >
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <div class="swiper-box">
            <div class="case-pic">
              <img :src="item.picUrl" class="pic" alt="" />
            </div>
            <div class="case-content">
              <div class="category">{{ item.menuName }}</div>
              <router-link :to="item.pageUrl" class="btn">查看详情</router-link>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper"
import "swiper/dist/css/swiper.css"
export default {
  name: "MoreCase",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    menuId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bannerList: [
        {
          picUrl: "https://image.hzhuihe.cn/new_website/other/kxsm.png",
          menuName: "开箱扫码",
          pageUrl: "/kxsm",
        },
        {
          picUrl: "https://image.hzhuihe.cn/new_website/other/sq.png",
          menuName: "店主社区",
          pageUrl: "/sqyx",
        },
        {
          picUrl: "https://image.hzhuihe.cn/new_website/other/clzj.png",
          menuName: "陈列自检",
          pageUrl: "/clzj",
        },
        {
          picUrl: "https://image.hzhuihe.cn/new_website/other/ydxp.png",
          menuName: "业代巡拍",
          pageUrl: "/ydxp",
        },
        {
          picUrl: "https://image.hzhuihe.cn/new_website/other/ddhx.png",
          menuName: "到店核销",
          pageUrl: "/ddhx",
        },
        {
          picUrl: "https://image.hzhuihe.cn/new_website/other/yylx.png",
          menuName: "壹元乐享",
          pageUrl: "/yylx",
        },
        {
          picUrl: "https://image.hzhuihe.cn/new_website/other/kgyl.png",
          menuName: "开盖有礼",
          pageUrl: "/kgyl",
        },
      ],
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 20,
        autoplay: true,
        slidesPerGroup: 4,
        speed: 1000,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    }
  },
}
</script>
<style lang="less" scoped>
/deep/ .swiper-button-prev {
  width: 60px;
  height: 60px;
  left: -100px;
  background: url("https://image.hzhuihe.cn/new_website/other/arrow_left.png")
    center no-repeat;
  background-size: contain;
}
/deep/ .swiper-button-next {
  width: 60px;
  height: 60px;
  right: -100px;
  background: url("https://image.hzhuihe.cn/new_website/other/arrow_right.png")
    center no-repeat;
  background-size: contain;
}
.more-case {
  padding: 55px 0 108px;
  background: #f7f9fa;
  .title {
    margin-bottom: 65px;
    text-align: center;
    font-size: 46px;
    color: #141414;
  }
  .banner {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    .swiper-box {
      height: 100%;
      text-align: center;
      border-radius: 10px;
      background: #fff;
      // border: 1px solid #FF7F00;
      overflow: hidden;
      .case-pic {
        width: 100%;
        height: 0;
        padding-bottom: 106.4%;
        overflow: hidden;
        .pic {
          display: block;
          width: 100%;
        }
      }
      .case-content {
        width: 100%;
        padding: 15px 0;
        text-align: center;
        // border-radius: 0 0 10px 10px;
        // border: 1px solid #FF7F00;
        // border-top: none;
        box-sizing: border-box;
        .category {
          font-size: 30px;
          color: #333;
        }
        .btn {
          font-size: 20px;
          text-decoration: underline;
          color: #ff7f00;
        }
      }
    }
  }
}
</style>
