<template>
    <div class="ppxq">
        <div class="ppxq-title">适用品牌需求</div>
        <div class="flex ppxq-content">
            <div :class="['ppxq-content_item', {'m0':index==list.length-1}]" v-for="(item, index) in list" :key="index">
                <div :class="['flex item-icon', {'special' : item.special}]">
                    <img :src="item.icon" alt="">
                </div>
                <div class="item-text" v-html="item.desc"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PPXQ',
    props: {
        list: {
            type: Array,
            default: () => []
        }
    }
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
.ppxq {
    padding: 40px 0 84px;
    .ppxq-title {
        margin-bottom: 88px;
        text-align: center;
        font-size: 46px;
        color: #141414;
    }
    .ppxq-content {
        &_item {
            margin-right: 135px;
            text-align: center;
            font-size: 22px;
            color: #333;
            .item-icon {
                height: 85px;
                margin: 0 auto 27px;
                img {
                    display: block;
                    height: 100%;
                    margin:0 auto;
                    align-self: center;
                }
                &.special {
                    img {
                        height: 53px;
                    }
                }
                
            }
            .item-text {
                white-space: pre-line;
            }
        }
    }
}
</style>