<template>
  <div id="app">
    <Nav :is-transparent="false" />
    <!-- banner -->
    <Banner
      source-url="https://image.hzhuihe.cn/new_website/kgyl/banner.png"
      title="开盖有礼"
      :sub-title="subTitle"
      alt-text="开盖有礼"
    />
    <!-- 适应品牌需求 -->
    <PPXQ :list="list" />
    <!-- 解决方案 -->
    <div class="jjfa">
      <div class="title">解决方案</div>
      <div class="flex jjfa-content">
        <div class="jjfa-content_item">
          <img
            src="https://image.hzhuihe.cn/new_website/kgyl/5.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text">定制奖励标准及活动规则</span>
        </div>
        <div class="jjfa-content_item m0">
          <img
            src="https://image.hzhuihe.cn/new_website/kgyl/6.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text">活动规则配置并生成码包</span>
        </div>
      </div>
      <div class="flex jjfa-content">
        <div class="jjfa-content_item">
          <img
            src="https://image.hzhuihe.cn/new_website/kgyl/7.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text">消费者购买产品开盖扫码</span>
        </div>
        <div class="jjfa-content_item">
          <img
            src="https://image.hzhuihe.cn/new_website/kgyl/8.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text">中奖领取红包并直接到账</span>
        </div>
        <div class="jjfa-content_item m0">
          <img
            src="https://image.hzhuihe.cn/new_website/kgyl/9.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text">数据追踪分析活动效果</span>
        </div>
      </div>
    </div>
    <!-- 方案优势 -->
    <div class="fays">
      <div class="title">方案优势</div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://image.hzhuihe.cn/new_website/kgyl/10.png" alt="" />
        </div>
        <div class="item-right">
          <span class="bold">奖励丰富</span>
          <span
            >支持现金、实物券、第三方资源等多种奖励形式，满足品牌多种营销需求</span
          >
        </div>
      </div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://image.hzhuihe.cn/new_website/kgyl/11.png" alt="" />
        </div>
        <div class="item-right">
          <span class="bold">全员营销</span>
          <span
            >通过一码多用，可以让店主、导购甚至消费者都变成品牌的推销员</span
          >
        </div>
      </div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://image.hzhuihe.cn/new_website/kgyl/12.png" alt="" />
        </div>
        <div class="item-right">
          <span class="bold">用户引流</span>
          <span
            >通过扫码关注公众号或点击小程序等引流设置，可以帮品牌商沉淀私域流量</span
          >
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <customer-case
      :menu-id="menuId"
      :title="caseTitle"
      :description="caseDesc"
      news-id="50"
      pic-url="https://image.hzhuihe.cn/new_website/khal/kgyl.png"
    ></customer-case>
    <!-- 方案保障 -->
    <FABZ />
    <!-- 更多方案 -->
    <more-case></more-case>
    <Bottom />
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue"
import Banner from "@/components/Banner.vue"
import PPXQ from "@/components/PPXQ.vue"
import CustomerCase from "@/components/CustomerCase.vue"
import FABZ from "@/components/FABZ.vue"
import MoreCase from "@/components/MoreCase.vue"
import Bottom from "@/components/Bottom.vue"
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "SQYX",
  components: {
    Nav,
    Banner,
    PPXQ,
    CustomerCase,
    FABZ,
    MoreCase,
    Bottom,
  },
  data() {
    return {
      subTitle:
        "基于“一品一码”技术，通过在商品内包装赋码，可叠加丰富的\n奖励形式，促进C端营销活动灵活落地。",
      caseTitle: "惠合科技X全球维生素饮料领军品牌",
      caseDesc:
        "该维生素饮料品牌于2003年上市，2020年开始与惠合科技在全国范围内开展B/C端营销活动，实现了亿级营销费用100%实投，沉淀C端用户数据，驱动精准营销。",
      menuId: "7",
      list: [
        {
          icon: "https://image.hzhuihe.cn/new_website/kgyl/1.png",
          desc: "制造消费动机\n转化潜在用户",
        },
        {
          icon: "https://image.hzhuihe.cn/new_website/kgyl/2.png",
          desc: "引导二次消费\n增加用户粘性",
        },
        {
          icon: "https://image.hzhuihe.cn/new_website/kgyl/3.png",
          desc: "丰富奖励形式\n实现捆绑销售",
        },
        {
          icon: "https://image.hzhuihe.cn/new_website/kgyl/4.png",
          desc: "沉淀私域流量\n驱动精准营销",
        },
      ],
    }
  },
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
#app {
  padding-top: 88px;
  .title {
    text-align: center;
    font-size: 46px;
    color: #141414;
  }
  .jjfa {
    padding: 50px 0 15px;
    background: #f7f9fa;
    .title {
      margin-bottom: 60px;
      text-align: center;
      font-size: 46px;
      color: #141414;
    }
    .jjfa-content {
      margin-bottom: 50px;
      &_item {
        text-align: center;
        margin-right: 50px;
        &-img {
          display: block;
          width: 410px;
          height: 260px;
          margin-bottom: 20px;
          background: #ffffff;
          img {
            display: block;
            width: 100%;
          }
        }
        &-text {
          position: relative;
          padding-left: 20px;
          font-size: 22px;
          font-weight: 400;
          color: #333333;
          box-sizing: border-box;
          &:before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            top: 14px;
            left: 0;
            background: #333;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .fays {
    padding: 50px 0;
    .title {
      margin-bottom: 50px;
    }
    .fays-item {
      margin-bottom: 26px;
      .item-left {
        width: 80px;
        height: 80px;
        background: #ffe8c7;
        align-items: center;
        img {
          display: block;
          max-width: 100%;
        }
      }
      .item-right {
        position: relative;
        width: 972px;
        height: 80px;
        padding-left: 30px;
        line-height: 80px;
        font-size: 22px;
        color: #333;
        background: #fffbf7;
        letter-spacing: 1px;
        box-sizing: border-box;
        &:before {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          margin-top: -4px;
          top: 50%;
          left: 15px;
          background: #333;
          border-radius: 50%;
        }
        .bold {
          margin-right: 20px;
          font-size: 26px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
