<template>
  <div id="app">
    <Nav :is-transparent="false"></Nav>
    <!-- <div class="Banner">
      <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <img class="banner_img" :src="item" alt="惠合科技" />
        </swiper-slide>
      </swiper>
    </div>-->
    <Banner
      source-url="https://image.hzhuihe.cn/new_website/video/sxh.mp4"
      :isImg="false"
      title="随心惠"
      :sub-title="subTitle"
      alt-text="随心惠"
    />
    <div class="flex title-list bg-theme-light">
      <div
        :class="[
          'flex title-item',
          { active: currentIndex === index },
          { m0: index == tabList.length - 1 },
        ]"
        v-for="(item, index) in tabList"
        :key="index"
        @click="handleChange(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="flex bg-theme-light wrapper wrapper1" id="id1" ref="wrapper1">
      <div class="flex desc-box">
        <div class="desc-title">承接线上流量</div>
        <div class="desc-text">
          消费者可以通过多渠道推广进入随心惠平台领取品牌优惠券。
        </div>
        <div class="flex desc-icon">
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/sxh/1-1.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>折扣券</p>
          </div>
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/sxh/1-2.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>满减券</p>
          </div>
          <div class="desc-icon_box m0">
            <img
              src="https://image.hzhuihe.cn/new_website/sxh/1-3.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>提货券</p>
          </div>
        </div>
      </div>
      <img
        class="pic"
        src="https://image.hzhuihe.cn/new_website/sxh/1.png"
        alt="e店佳"
      />
    </div>
    <div class="flex wrapper wrapper2" id="id2" ref="wrapper2">
      <img
        class="pic"
        src="https://image.hzhuihe.cn/new_website/sxh/2.png"
        alt="e店佳"
      />
      <div class="flex desc-box">
        <div class="desc-title">C端活动落地</div>
        <div class="desc-text">为C端活动线下落地提供执行指引和核销落地。</div>
        <div class="flex desc-icon">
          <div class="desc-icon_box">
            <img
              src="https://image.hzhuihe.cn/new_website/sxh/2-1.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>引导消费者进店</p>
          </div>
          <div class="desc-icon_box m0">
            <img
              src="https://image.hzhuihe.cn/new_website/sxh/2-2.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>活动承接详情展示</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 四端在线start -->
    <SDZX></SDZX>
    <!-- 平台保障start -->
    <PTBZ></PTBZ>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue"
import Banner from "@/components/Banner.vue"
import Bottom from "@/components/Bottom.vue"
import SDZX from "@/components/SDZX.vue"
import PTBZ from "@/components/PTBZ.vue"
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/dist/css/swiper.css";
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "App",
  components: {
    Nav,
    Banner,
    Bottom,
    SDZX,
    PTBZ,
    // swiper,
    // swiperSlide,
  },
  data() {
    return {
      subTitle:
        "面向消费者的营销互动平台，为品牌在传统渠道的C端营销活\n动提供落地支撑。",
      bannerList: [
        "https://image.hzhuihe.cn/new_website/vmsBanner.png",
        "https://image.hzhuihe.cn/new_website/vmsBanner.png",
      ],
      swiperOptions: {
        autoplay: true,
        speed: 1000,
        loop: true,
        // Some Swiper option/callback...
      },
      tabList: ["承接线上流量", "C端活动落地"],
      currentIndex: 0,
    }
  },
  methods: {
    handleChange(index) {
      this.currentIndex = index
      let offsetTop = this.$refs[`wrapper${index + 1}`].offsetTop - 88
      try {
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        })
      } catch (e) {
        window.scrollTo(0, offsetTop)
      }
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
@import url("~@/assets/global.less");
#app {
  padding-top: 88px;
}
.Banner {
  min-width: 1400px;
  width: 100%;
  height: 0;
  padding-bottom: 33.8%;
  overflow: hidden;
  position: relative;
  .swiper {
    width: 100%;
  }
  .banner_img {
    min-width: 1400px;
    width: 100%;
    display: block;
    object-fit: cover;
  }
}
.title-list {
  padding: 4vw 0;
  .title-item {
    position: relative;
    width: 13vw;
    height: 5.5vw;
    margin-right: 3vw;
    padding-bottom: 0.6vw;
    font-size: 1vw;
    font-weight: 400;
    color: #333333;
    background: url("https://image.hzhuihe.cn/new_website/tabBg.png") center top
      no-repeat;
    background-size: 100% 100%;
    background-clip: content-box;
    border-radius: 4px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
    justify-content: center;
    align-items: center;
    &:before {
      content: "";
      position: absolute;
      top: 20px;
      width: 30px;
      height: 4px;
      background: #333;
      border-radius: 2px;
    }
    &.active {
      color: #fff;
      box-shadow: none;
      background: url("https://image.hzhuihe.cn/new_website/tabActiveBg.png")
        top center no-repeat;
      background-size: 100% 100%;
      background-clip: border-box;
      &:before {
        background: #fff;
      }
    }
  }
}
.wrapper {
  align-items: center;
  .desc-box {
    // max-width: 36vw;
    // margin: 0 2vw;
    flex-direction: column;
    font-size: 1vw;
    align-items: center;
    .desc-title {
      margin-bottom: 2vw;
      font-size: 2vw;
      color: #141414;
    }
    .desc-text {
      margin-bottom: 1vw;
    }
    .desc-icon {
      justify-content: space-around;
      .desc-icon_box {
        margin-right: 4vw;
        text-align: center;
        .icon-box_img {
          width: 76px;
          margin-bottom: 1vw;
        }
        p {
          font-size: 1vw;
        }
      }
    }
  }
  &.wrapper1 {
    padding-bottom: 2.2vw;
    .desc-box {
      margin-right: 75px;
    }
    .pic {
      width: 427px;
    }
  }
  &.wrapper2 {
    padding: 20px 0;
    .desc-box {
      margin-left: 157px;
    }
    .pic {
      width: 554px;
    }
  }
}
</style>
