<template>
  <footer>
    <div class="footer_top" v-if="isShowContact">
      <p class="footer_top_title">找惠合，解锁传统渠道营销新玩法</p>
      <p class="footer_top_subtitle">
        已落地400+陈列活动、300+扫码活动、400+C端消费者活动
      </p>
      <router-link
        class="consult"
        :to="`/contact?from=${pathName[getPath2name()]}&moudle=底部广告区`"
        >立即咨询</router-link
      >
    </div>
    <div class="footer_middle">
      <div class="footer_items">
        <div class="footer_menu">营销平台</div>
        <router-link
          v-for="item in marketPlatform"
          :key="item.href"
          :class="{ footer_item: true, hover: hover === item.name }"
          :to="item.href"
          ><div @click="sensors('营销平台', item.name)">
            {{ item.name }}
          </div></router-link
        >
      </div>
      <div class="footer_items">
        <div class="footer_menu">营销方案</div>
        <router-link
          v-for="item in plans"
          :key="item.href"
          :class="{ footer_item: true, hover: hover === item.name }"
          :to="item.href"
          ><div @click="sensors('营销方案', item.name)">
            {{ item.name }}
          </div></router-link
        >
      </div>
      <div class="footer_items">
        <div class="footer_menu">关于我们</div>
        <!-- <router-link
                    v-for="item in about"
                    :key="item.href"
                    :class="{ footer_item: true, 'hover': hover === item.name }" :class="{ hover: item.name === hover }"  class="ignore_nav_alert_item"
                    :to="item.href"
                >{{ item.name }}</router-link>-->
        <div v-for="item in about" :key="item.href">
          <div v-if="item.name != '加入我们'">
            <router-link
              :to="item.href"
              :class="{ footer_item: true, hover: hover === item.name }"
              ><div @click="sensors('关于我们', item.name)">
                {{ item.name }}
              </div></router-link
            >
          </div>
          <div v-if="item.name == '加入我们'">
            <a
              :href="item.href"
              :class="{ footer_item: true, hover: hover === item.name }"
              ><div @click="sensors('关于我们', item.name)">
                {{ item.name }}
              </div></a
            >
          </div>
        </div>
      </div>
      <div class="footer_items">
        <div class="footer_menu">联系我们</div>
        <div class="footer_item">客服热线：{{ pageData.consumerHotline }}</div>
        <a class="footer_item">邮箱：{{ pageData.companyEmail }}</a>
      </div>
      <div class="footer_img">
        <div class="footer_img_item">
          <img src="https://image.hzhuihe.cn/new_website/gzh.png?v=1" alt />
          <div>关注惠合科技公众号</div>
        </div>
        <div class="footer_img_item">
          <img src="https://image.hzhuihe.cn/new_website/index/edj.jpg" alt />
          <div>关注e店佳公众号</div>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <span>{{ pageData.copyright }}</span>
      <span class="code"
        >工信部备案号：<a
          href="https://beian.miit.gov.cn/"
          class="link"
          target="_blank"
          >{{ pageData.recordNumber }}</a
        ></span
      >
      <span>公安备案 : {{ pageData.securityRecord }}</span>
    </div>
  </footer>
</template>

<script>
import { marketPlatform, pathName, about, plans } from "@/utils/enum.js"
import { getPath2name } from "@/utils/utils.js"
import { doPost } from "@/utils/request"
export default {
  name: "Bottom",
  props: {
    isShowContact: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      marketPlatform,
      about,
      plans,
      hover: pathName[getPath2name()],
      pageData: {},
      pathName,
    }
  },
  created() {
    doPost("pms/officialWebsiteSetting/doGetOneByNoLogin.json").then((res) => {
      this.pageData = res
    })
  },
  methods: {
    getPath2name,
    sensors(navName, menuMame) {
      window.sensors.track("nav_menu_click", {
        current_page: pathName[getPath2name()],
        belong_area: "底部",
        belong_first_navename: navName,
        nav_menu_name: menuMame,
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import url("~@/assets/global.less");
.footer_top {
  height: 400px;
  background: url("https://image.hzhuihe.cn/new_website/bottomBg.png") center
    no-repeat;
  background-size: auto 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .footer_top_title {
    font-size: 2.5vw;
    color: #fff;
  }
  .footer_top_subtitle {
    font-size: 1vw;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .consult {
    width: 180px;
    height: 48px;
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: @primary-color;
    cursor: pointer;
  }
}
.footer_middle {
  background: #151515;
  min-height: 230px;
  padding-left: 5.83vw;
  overflow: hidden;
  box-sizing: border-box;
  .footer_items {
    padding-top: 30px;
    margin-left: 5vw;
    float: left;
    width: 9.4vw;
    .footer_menu {
      font-size: 18px;
      color: #fff;
      font-weight: 600;
      margin-bottom: 14px;
    }
    .footer_item {
      margin-bottom: 16px;
      font-size: 0.73vw;
      color: #fff;
      cursor: pointer;
      &:hover {
        color: @primary-color;
      }
    }
  }
  .footer_img {
    margin-left: 2.4vw;
    padding-top: 1.04vw;
    padding-left: 2.604vw;
    float: left;
    position: relative;
    &::before {
      content: "";
      width: 1px;
      height: 9.375vw;
      background: #323232;
      position: absolute;
      top: calc(1.04vw + 10px);
      left: 0;
    }
    .footer_img_item {
      width: 9.27vw;
      margin-right: 2.08vw;
      float: left;
      font-size: 14px;
      color: #fff;
      text-align: center;
      img {
        width: 100%;
      }
    }
  }
}
.footer_bottom {
  height: 65px;
  background: #000;
  color: #fff;
  font-size: 12px;
  line-height: 65px;
  padding-left: 15.625vw;
  .code {
    margin-left: 4.2vw;
    margin-right: 5.83vw;
    .link {
      display: inline-block;
      color: #fff;
      font-size: 12px;
    }
  }
}
.hover {
  color: @primary-color!important;
}
</style>
