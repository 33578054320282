import { Md5 } from '@/utils/md5.js'
// import Vue from 'vue'
// import {
//   ACCESS_TOKEN,
//   SELECT_BRAND
// } from '@/store/mutation-types'
function getSign (context) { // MD5加密
  const timestamp = context.timestamp
  // const token = context.token || ''
  // const content = `${token}${timestamp}key=MKnEu6zaS04N23XoMUL8GOwOKIQwXMvT`
  const content = `${timestamp}key=MKnEu6zaS04N23XoMUL8GOwOKIQwXMvT`
  return Md5(content).toUpperCase()
}

function getContext () { // 获取后台传入的公用数据
  const result = {}
  result['clientType'] = 'web'
//   result['token'] = getToken()
  result['clientMac'] = ''
  result['timestamp'] = `${Date.now()}`
//   result['brandId'] = getBrandId()
  return result
}



export function getPramsData (prams = '') { // 获取传入后台的具体DATA
  const result = {}
  const context = getContext()
  result['data'] = prams
  result['context'] = context
  result['sign'] = getSign(context)
  return result
}
