import Vue from 'vue'
// const fs = require('fs')
// import fs from 'fs'
// const path = require('path')
import Router from 'vue-router'
import Index from "../pages/index/App.vue"
import About from "../pages/aboutUs/App.vue"
import News from "../pages/news/App.vue"
import Case from "../pages/case/App.vue"
import Clzj from "../pages/clzj/App.vue"
import Contact from "../pages/contact/App.vue"
import Ddhx from "../pages/ddhx/App.vue"
import Edj from "../pages/edj/App.vue"
import Kgyl from "../pages/kgyl/App.vue"
import Kqt from "../pages/kqt/App.vue"
import Sxh from "../pages/sxh/App.vue"
import Kxsm from "../pages/kxsm/App.vue"
import NewsDetail from "../pages/newsDetail/App.vue"
import Sqyx from "../pages/sqyx/App.vue"
import Vms from "../pages/vms/App.vue"
import Ydxp from "../pages/ydxp/App.vue"
import Yylx from "../pages/yylx/App.vue"
Vue.use(Router)

let router =  new Router({
  routes: [
    {
      path: '/',
      name: 'Index',
      // redirect:"/index"
      component: Index,
      meta: {
        title: ''
      }
    },
    {
      path: '/index',
      name: 'Index',
      component: Index,
      meta: {
        title: ''
      }
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: {
        title: '公司简介'
      }
    },
    {
      path: '/news',
      name: 'news',
      component: News,
      meta: {
        title: ''
      }
    },
    {
      path: '/case',
      name: 'case',
      component: Case,
      meta: {
        title: ''
      }
    },
    {
      path: '/clzj',
      name: 'clzj',
      component: Clzj,
      meta: {
        title: ''
      }
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
      meta: {
        title: ''
      }
    },
    {
      path: '/ddhx',
      name: 'ddhx',
      component: Ddhx,
      meta: {
        title: ''
      }
    },
    {
      path: '/edj',
      name: 'edj',
      component: Edj,
      meta: {
        title: ''
      }
    },
    {
      path: '/kgyl',
      name: 'kgyl',
      component: Kgyl,
      meta: {
        title: ''
      }
    },
    {
      path: '/kqt',
      name: 'kqt',
      component: Kqt,
      meta: {
        title: ''
      }
    },
    {
      path: '/kxsm',
      name: 'kxsm',
      component: Kxsm,
      meta: {
        title: ''
      }
    },
    {
      path: '/newsDetail',
      name: 'newsDetail',
      component: NewsDetail,
      meta: {
        title: ''
      }
    },
    {
      path: '/sqyx',
      name: 'sqyx',
      component: Sqyx,
      meta: {
        title: ''
      }
    },
    {
      path: '/vms',
      name: 'vms',
      component: Vms,
      meta: {
        title: ''
      }
    },
    {
      path: '/ydxp',
      name: 'ydxp',
      component: Ydxp,
      meta: {
        title: ''
      }
    },
    {
      path: '/yylx',
      name: 'yylx',
      component: Yylx,
      meta: {
        title: ''
      }
    },
    {
      path: '/sxh',
      name: 'sxh',
      component: Sxh,
      meta: {
        title: ''
      }
    },
  ],
  base:"/",
  mode: 'history'
})

// router.beforeEach((to,from,next)=>{
//     console.log('我来了')
//     const dommain = location.hostname // 主机域名
//     if( (dommain.search('localhost') !== -1)){
//         next()
//         return
//     }//本地打包阶段不执行此代码
//     try {
//         if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
//             window.location.href = "/huihePhone" + to.fullPath;
//         }
//     } catch (e) { 
//       console.log(e)
//     }
//     next()
// })
export default router